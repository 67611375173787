import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/builds/frontend/chameleon-docs/src/components/Layout/UpdateLayout.js";
import List from 'components/LinkCard/List';
import ListItem from 'components/LinkCard/ListItem';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h3 {...{
      "id": "a-step-towards-better-performance",
      "style": {
        "position": "relative"
      }
    }}>{`A step towards better performance`}</h3>
    <p>{`Small changes in our React component-library will provide performance 🚀
benefits in the future. At this moment we introduced sideEffects and made some
changes in our Illustration components to enable better treeshaking (dead code
elimination).`}</p>
    <List my={8} mdxType="List">
  <ListItem title="Migration guide" description="Read more about illustration changes" to="/updates/migration" mdxType="ListItem" />
    </List>
    <h3 {...{
      "id": "nb-theme-updates",
      "style": {
        "position": "relative"
      }
    }}>{`NB theme updates`}</h3>
    <p>{`Updated existing NB theme to match better the productions implementation. So it
can be used in fragments. This includes Heading 3 line-height. Branded Heading
large and small update.`}</p>
    <h3 {...{
      "id": "playroom-beta",
      "style": {
        "position": "relative"
      }
    }}>{`Playroom (Beta)`}</h3>
    <p>{`We're introducing playroom for quick prototyping across different brands with
only one code snippet. The url is sharable, so you can share your code snippet
with a simple link. All React components are accessible here. Check it out here:
`}<a parentName="p" {...{
        "href": "https://chameleon.mediahuis.be/playroom"
      }}>{`https://chameleon.mediahuis.be/playroom`}</a></p>
    <h3 {...{
      "id": "new-staging-environment",
      "style": {
        "position": "relative"
      }
    }}>{`New Staging Environment`}</h3>
    <p>{`To be more transparent, we're introducing a new staging domain. Here we will
update next releases before they come to production.
`}<a parentName="p" {...{
        "href": "https://staging-chameleon.mediahuis.be"
      }}>{`https://staging-chameleon.mediahuis.be`}</a></p>
    <h3 {...{
      "id": "make-autocomplete-prop-accessible-for-chrome-autocomplete-bug",
      "style": {
        "position": "relative"
      }
    }}>{`Make 'autocomplete' prop accessible for chrome autocomplete bug`}</h3>
    <p>{`Chrome ignores autocomplete="off", that's why we've made the autocomplete prop
accessible to the component. So if it can be overwritten where needed. More info
can be found here:
`}<a parentName="p" {...{
        "href": "https://stackoverflow.com/questions/12374442/chrome-ignores-autocomplete-off"
      }}>{`https://stackoverflow.com/questions/12374442/chrome-ignores-autocomplete-off`}</a></p>
    <h3 {...{
      "id": "figma-plugin-experimentation",
      "style": {
        "position": "relative"
      }
    }}>{`Figma plugin experimentation`}</h3>
    <p>{`For a better workflow we're investigation what Figma plugins can do for us, and
what this means for our current implementation.`}</p>
    <h3 {...{
      "id": "full-changelog",
      "style": {
        "position": "relative"
      }
    }}>{`Full changelog`}</h3>
    <p>{`Check out the full changelog for more information.`}</p>
    <List my={8} mdxType="List">
  <ListItem title="v1.6.0 changelog" description="Get a full overview of what's changed in the changelog" to="/updates/changelog" mdxType="ListItem" />
    </List>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      